<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <b>All Profiles</b>
            <div class="card-header-actions">
              <CButton
                color="primary"
                @click="addProfileClicked()">
                Add Profile
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert
              color="danger"
              closeButton
              :show.sync="showAlert"
            >
              {{ alertContent }}
            </CAlert>
            <CRow>
              <CCol sm="3">
                <CSelect
                  label="Subscribe status"
                  :options="['ALL', 'SUBSCRIBE', 'UNSUBSCRIBE']"
                  :value="subscribeStatus"
                  @update:value="onSubscribeStatusChanged"
                />
              </CCol>
            </CRow>
            <CDataTable
              striped
              :items="items"
              :fields="getFields"
              :items-per-page="10"
              :itemsPerPageSelect="true"
              column-filter
              :loading="loading"
              border

              @pagination-change="paginationChanged"
            >
              <template #_id="{item}">
                <td>
                  <CButton
                    color="link"
                    size="sm"
                    @click="onClickId(item)">{{ item._id }}
                  </CButton>
                </td>
              </template>
              <template #image="{item}">
                <td>
                  <avatar
                    :username="item.user_name"
                    src=""
                    :size="50">
                  </avatar>
                </td>
              </template>
              <template #conversation="{item}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    variant="outline"
                    size="sm"
                    @click="conversationClicked(item)">
                    Conversation
                  </CButton>
                </td>
              </template>
              <template #update="{item}">
                <td class="py-2">
                  <CButton
                    color="primary"
                    square
                    variant="outline"
                    size="sm"
                    @click="updateClicked(item)">
                    Update
                  </CButton>
                </td>
              </template>
              
              <template #delete="{item}">
                <td class="py-2">
                  <CButton
                    color="danger"
                    square
                    variant="outline"
                    size="sm"
                    @click="deleteModal = true; deleteItem = item._id;">
                    Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>
            <CPagination
              :activePage.sync="currPage"
              :pages.sync="totalPage"
              align="center"
              @update:activePage="pageChanged"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="Delete Profile?"
      :show.sync="deleteModal"
      @update:show="onCloseModal"
    >
      <p>Are you sure you want to delete this item?</p>
      <p>ID: {{ deleteItem }}</p>
    </CModal>
  </div>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'
import TimeUtils from '../../common/TimeUtils'
import Avatar from 'vue-avatar'
import Vue from 'vue'

const normalFields = [
  { key: '_id', label: 'ID', _style: 'width:50px' },
  { key: 'user_name', label: 'User Name'},
  { key: 'app_id', label: 'List ID'},
  { key: 'phone_number', label: 'Phone Number'},
  { key: 'region_code', label: 'Region Code'},
  { key: 'time_created', label: 'Time Created'},
  { key: 'time_updated', label: 'Time Updated'},
  { 
    key: 'conversation', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  },
  { 
    key: 'update', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  },
  { 
    key: 'delete', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
];

const unsubscribeFields = [
  { key: '_id', label: 'ID', _style: 'width:50px' },
  { key: 'user_name', label: 'User Name'},
  { key: 'app_id', label: 'List ID'},
  { key: 'phone_number', label: 'Phone Number'},
  { key: 'region_code', label: 'Region Code'},
  { key: 'time_created', label: 'Time Created'},
  { key: 'time_updated', label: 'Time Updated'},
  { key: 'unsubscribe_time', label: 'Unsubscribe Time'},
  { 
    key: 'conversation', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  },
  { 
    key: 'update', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  },
  { 
    key: 'delete', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
];

export default {
  name: 'Profiles',
  created() {
    Vue.component('avatar', Avatar);
    this.initProfiles();
  },
  data () {
    return {
      items: [],
      totalPage: 0,
      currPage: 1,
      perPage: 10,

      // filter
      subscribeStatus: this.$route.query.subscribe_status ? this.$route.query.subscribe_status : "ALL",

      loading: false,
      showAlert: false,
      alertContent: "",

      deleteModal: false,
      deleteItem: ""
    }
  },
  computed: {
    getFields: function() {
      if (this.subscribeStatus === "UNSUBSCRIBE") {
        return unsubscribeFields;
      }
      return normalFields;
    }
  },
  methods: {
    resetData() {
      var _this = this;
      _this.items = [];
      _this.showAlert = false;
      _this.alertContent = "";

      _this.totalPage = 0;
      _this.currPage = 1;
    },
    getProfiles(from, size) {
      var _this = this;
      _this.resetData();
      _this.loading = true;
      
      AxiosBase.post("/profile/list", {
        from: from,
        size: size,

        subscribe_status: _this.subscribeStatus
      })
      .catch(function(error) {
        console.log(error);
        _this.loading = false;
      })
      .then(function(res) {
        let resProfiles = res?.data?.items;
        let total = res?.data?.total;
        let from = res?.data?.from;

        if (resProfiles) {
          for (let item of resProfiles) {
            item.time_created = TimeUtils.formatTime(item.time_created);
            item.time_updated = TimeUtils.formatTime(item.time_updated);

            if (item.subscriber_meta && item.subscriber_meta.unsubscribe_time) {
              item.unsubscribe_time = TimeUtils.formatTime(item.subscriber_meta.unsubscribe_time);
            }
            _this.items.push(item);
          }

          _this.totalPage = parseInt(total / size) + 1;
          _this.currPage = parseInt(from / size) + 1;
        } else {
          // show errors
          _this.showAlert = true;
          _this.alertContent = `Error: ${res}`;
          console.log(JSON.stringify(res));
        }
        _this.loading = false;
      });
    },
    initProfiles() {
      this.getProfiles(0, this.perPage);
    },
    deleteProfile(oid) {
      var _this = this;
      _this.loading = true;
      
      AxiosBase.delete("/profile/delete", {
        params: {
          oid: oid
        }
      })
      .catch(function(error) {
        console.log(error);
        _this.loading = false;
      })
      .then(function(res) {
        if (res?.status === 200) {
          _this.initProfiles();
        } else {
          // show errors
          _this.showAlert = true;
          _this.alertContent = `Error: ${res}`;
          console.log(JSON.stringify(res));
          _this.loading = false;
        }
      });
    },
    addProfileClicked() {
      this.$router.push('/profiles/add');
    },
    conversationClicked(item) {
      this.$router.push(`/conversation/detail/${item.phone_number}`);
    },
    updateClicked(item) {
      this.$router.push(`/profiles/update/${item._id}`);
    },
    pageChanged(pageNumber, reduced) {
      this.getProfiles(this.perPage * (pageNumber - 1), this.perPage);
    },
    paginationChanged(perPage) {
      this.perPage = perPage;
      this.initProfiles();
    },
    onCloseModal(status, evt, accept) {
      if (accept) {
        this.deleteProfile(this.deleteItem);
      }
    },
    onClickId(item) {
      this.$router.push('/profiles/detail/' + item._id);
    },
    onSubscribeStatusChanged(value) {
      this.subscribeStatus = value;
      this.$router.push({query: {...this.$route.query, subscribe_status: value}});

      this.getProfiles(0, this.perPage);
    }
  }
}
</script>
