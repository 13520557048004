var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',[_c('CCard',[_c('CCardHeader',[_c('b',[_vm._v("All Profiles")]),_c('div',{staticClass:"card-header-actions"},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addProfileClicked()}}},[_vm._v(" Add Profile ")])],1)]),_c('CCardBody',[_c('CAlert',{attrs:{"color":"danger","closeButton":"","show":_vm.showAlert},on:{"update:show":function($event){_vm.showAlert=$event}}},[_vm._v(" "+_vm._s(_vm.alertContent)+" ")]),_c('CRow',[_c('CCol',{attrs:{"sm":"3"}},[_c('CSelect',{attrs:{"label":"Subscribe status","options":['ALL', 'SUBSCRIBE', 'UNSUBSCRIBE'],"value":_vm.subscribeStatus},on:{"update:value":_vm.onSubscribeStatusChanged}})],1)],1),_c('CDataTable',{attrs:{"striped":"","items":_vm.items,"fields":_vm.getFields,"items-per-page":10,"itemsPerPageSelect":true,"column-filter":"","loading":_vm.loading,"border":""},on:{"pagination-change":_vm.paginationChanged},scopedSlots:_vm._u([{key:"_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"link","size":"sm"},on:{"click":function($event){return _vm.onClickId(item)}}},[_vm._v(_vm._s(item._id)+" ")])],1)]}},{key:"image",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('avatar',{attrs:{"username":item.user_name,"src":"","size":50}})],1)]}},{key:"conversation",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","square":"","variant":"outline","size":"sm"},on:{"click":function($event){return _vm.conversationClicked(item)}}},[_vm._v(" Conversation ")])],1)]}},{key:"update",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","square":"","variant":"outline","size":"sm"},on:{"click":function($event){return _vm.updateClicked(item)}}},[_vm._v(" Update ")])],1)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"danger","square":"","variant":"outline","size":"sm"},on:{"click":function($event){_vm.deleteModal = true; _vm.deleteItem = item._id;}}},[_vm._v(" Delete ")])],1)]}}])}),_c('CPagination',{attrs:{"activePage":_vm.currPage,"pages":_vm.totalPage,"align":"center"},on:{"update:activePage":[function($event){_vm.currPage=$event},_vm.pageChanged],"update:active-page":function($event){_vm.currPage=$event},"update:pages":function($event){_vm.totalPage=$event}}})],1)],1)],1)],1),_c('CModal',{attrs:{"title":"Delete Profile?","show":_vm.deleteModal},on:{"update:show":[function($event){_vm.deleteModal=$event},_vm.onCloseModal]}},[_c('p',[_vm._v("Are you sure you want to delete this item?")]),_c('p',[_vm._v("ID: "+_vm._s(_vm.deleteItem))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }